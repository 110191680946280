import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useAuth } from '../AuthContext';
import { useTheme } from '../ThemeContext';
import TextToggle from './TextToggle';
import '../css/style.scss';
import ProgressBar from 'react-bootstrap/ProgressBar';

function CreatePost() {
  const API_URL = process.env.REACT_APP_API_URL;
  const CHAT_KEY = process.env.REACT_APP_CHAT_KEY;
  const location = useLocation();
  const navigate = useNavigate();
  const { theme, postLanguage } = location.state || {};
  const { niche, audience, audienceCharacteristics, setAudienceCharacteristics, contentGoal, setContentGoal, textTone, setTextTone, productService, setProductService, textCustomisation, setTextCustomisation, ideas, setIdeas, selectedLanguage, setSelectedLanguage } = useTheme();
  const [postContent, setPostContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [copySuccess, setCopySuccess] = useState('');
  const { t } = useTranslation();
  const { user, logout } = useAuth();

  const { selectedProjectId, stateActiveTab: stateActiveTab } = location.state || {};

  const token = localStorage.getItem('token');

  useEffect(() => {
    if (theme) { 
      generatePost(theme); 
    }
  }, [theme]); 

  const generatePost = async (theme) => {
    setLoading(true);

    setProgress(0); 
    
    const interval = setInterval(() => {
      setProgress((prev) => (prev + 1 <= 100 ? prev + 1 : 100)); 
    }, 150); 

    try {
      const contentParts = [];

      if (niche) contentParts.push(`Niche: ${niche}`);
      if (audience) contentParts.push(`Audience: ${audience}`);
      if (audienceCharacteristics) contentParts.push(`Audience characteristics: ${audienceCharacteristics}`);
      if (contentGoal) contentParts.push(`Goal: ${contentGoal}`);
      if (textTone) contentParts.push(`Tone and style: ${textTone}`);
      if (productService) contentParts.push(`Product or service: ${productService}`);

      let customisationPart = '';

      if (textCustomisation) {
        customisationPart = `Adapt the output to match the writing style of the provided sample: ${textCustomisation}. Pay close attention to the tone, word choice, sentence structure, and overall flow of the sample text. Ensure that the final output maintains the individual’s unique voice and style, making it feel as though they wrote it`;
      }

      const contentQuery = contentParts.join(', ');

      const languageToUse = postLanguage || selectedLanguage;

      const languagePart = {
        'ru': 'in Russian',
        'lv': 'in Latvian',
        'es': 'in Spanish'
      }[languageToUse] || 'in English';

      const response = await axios.post( `${API_URL}/openai_proxy`, {
        model: "gpt-4o-2024-08-06",
        messages: [
          { role: "system", content: "You are an assistant specializing in creating ideas for Instagram posts." },
          { role: "user", content: `You need to put in effort and create a post on the topic "${theme}". Based on: ${contentQuery}. Make sure to write the entire text in ${languagePart}. ${customisationPart}. Make it an excellent result, so I can be proud of it!` }
        ],
        temperature: 0.9,
        top_p: 0.95,
      }, {
        headers: {
          'Authorization': `Bearer ${CHAT_KEY}`,
          'Content-Type': 'application/json',
        }
      });

      const newContent = response.data.choices[0].message.content;

      const cleanedContent = cleanText(newContent); 
      setPostContent(cleanedContent);
      
      savePost(theme, cleanedContent);

    } catch (error) {
      setPostContent(t('error_while_processing') + error.message);
    } finally {
      setLoading(false);
    }
  };

  const cleanText = (text) => {
    return text.replace(/\*/g, '').replace(/###/g, '');
  };

  const handleCopyText = () => {
    const textArea = document.createElement('textarea');
    textArea.value = postContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy'); 
    document.body.removeChild(textArea);
    setCopySuccess(t('text_copied')); 
  };

  const savePost = async (theme, content) => {
    try {
      const response = await axios.post(`${API_URL}/posts`, {
        theme_name: theme,
        content: content
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
    } catch (error) {
      console.error('Error saving post:', error);
    }
  };

  return (
    <div className="post_container">
      <span className="post_container_back_link" onClick={() => navigate('/projects', { state: { selectedProjectId, stateActiveTab: stateActiveTab } })}>{t('back')}</span>

      <h2>{t('post_result_heading')} <TextToggle text={theme} /></h2>

      {loading && (
        <div className="loading-progress-bar">
          <ProgressBar animated now={progress} label={`${progress}%`} /> 
        </div>
      )}

      {postContent && <div className="post-preview">{postContent}</div>}

      {postContent && (
        <button onClick={handleCopyText}>{t('copy_text')}</button> 
      )}

      {copySuccess && <div className="copy-success">{copySuccess}</div>}
    </div>
  );
}

export default CreatePost;