import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useAuth } from '../AuthContext';
import { useTheme } from '../ThemeContext';
import { useStory } from '../StoryContext';
import TextToggle from './TextToggle';
import '../css/style.scss';
import ProgressBar from 'react-bootstrap/ProgressBar';

function CreateStory() {
  const API_URL = process.env.REACT_APP_API_URL;
  const CHAT_KEY = process.env.REACT_APP_CHAT_KEY;
  const location = useLocation();
  const navigate = useNavigate();
  const { theme, duration, postLanguage } = location.state || {};

  const { projectId, niche, audience, audienceCharacteristics, setAudienceCharacteristics, contentGoal, setContentGoal, textTone, setTextTone, productService, setProductService, textCustomisation, setTextCustomisation, ideas, setIdeas, selectedLanguage, setSelectedLanguage } = useTheme();

  const { storyGoal, setStoryGoal, storyTheme, setStoryTheme, storyCharacter, setStoryCharacter, storyProblem, setStoryProblem, storySolution, setStorySolution, storyResult, setStoryResult, storyEmotions, setStoryEmotions, storyMoments, setStoryMoments, storyCta, setStoryCta, storyQty, setStoryQty, selectedStoryLanguage, setSelectedStoryLanguage } = useStory();

  const [storyContent, setStoryContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [copySuccess, setCopySuccess] = useState('');
  const { t } = useTranslation();
  const { user, logout } = useAuth();

  const token = localStorage.getItem('token');

  useEffect(() => {
    if (storyTheme) {
      generateStory(storyTheme);
    }
  }, [theme, duration]);

  const generateStory = async (storyTheme) => {
    setLoading(true);

    setProgress(0);
    
    const interval = setInterval(() => {
      setProgress((prev) => (prev + 1 <= 100 ? prev + 1 : 100));
    }, 150);
    try {
      const contentParts = [];

      if (niche) contentParts.push(`Niche: ${niche}`);
      if (audience) contentParts.push(`Audience: ${audience}`);
      if (audienceCharacteristics) contentParts.push(`Audience characteristics: ${audienceCharacteristics}`);
      if (productService) contentParts.push(`Product or service: ${productService}`);

      const contentQuery = contentParts.join(', ');

      const storyParts = [];

      if (storyGoal) storyParts.push(`Storytelling goal: ${storyGoal}`);
      if (storyTheme) storyParts.push(`Theme: ${storyTheme}`);
      if (storyCharacter) storyParts.push(`Main characters: ${storyCharacter}`);
      if (storyProblem) storyParts.push(`The problem the main character faces at the start: ${storyProblem}`);
      if (storySolution) storyParts.push(`Solution to the problem: ${storySolution}`);
      if (storyResult) storyParts.push(`Changes after using the proposed solution: ${storyResult}`);
      if (storyEmotions) storyParts.push(`Emotions to evoke in the audience: ${storyEmotions}`);
      if (storyMoments) storyParts.push(`Unique or interesting details: ${storyMoments}`);
      if (storyCta) storyParts.push(`Desired audience action after reading: ${storyCta}`);
      if (storyQty) storyParts.push(`Number of stories needed to tell the full narrative: ${storyQty}`);

      const storyQuery = storyParts.join(', ');

      let customisationPart = '';

      if (textCustomisation) {
        customisationPart = `Adapt the output to match the writing style of the provided sample: ${textCustomisation}. Pay close attention to the tone, word choice, sentence structure, and overall flow of the sample text. Ensure that the final output maintains the individual’s unique voice and style, making it feel as though they wrote it`;
      }

      const languageToUse = selectedStoryLanguage || selectedLanguage;

      const languagePart = {
        'ru': 'in Russian',
        'lv': 'in Latvian',
        'es': 'in Spanish'
      }[languageToUse] || 'in English';

      const response = await axios.post(`${API_URL}/openai_proxy`, {
        model: "gpt-4o-2024-08-06",
        messages: [
          { role: "system", content: "You are an assistant specializing in storytelling for Instagram." },
          { 
            role: "user", 
            content: `You need to put in effort and create a storytelling piece using this data: ${storyQuery}. Consider this as the project information: ${contentQuery}. Make sure to write the entire text ${languagePart}. ${customisationPart}. Make it an excellent result, so I can be proud of it!`
          }
        ],
        temperature: 0.9,
        top_p: 0.95,
      }, {
        headers: {
          'Authorization': `Bearer ${CHAT_KEY}`,
          'Content-Type': 'application/json',
        }
      });

      const newContent = response.data.choices[0].message.content;
      const cleanedContent = cleanText(newContent); // Очистка текста
      setStoryContent(cleanedContent);
      
      saveStory(storyTheme, cleanedContent);

    } catch (error) {
      console.error('Error when fetching data:', error.response ? error.response.data : error.message);
      setStoryContent(t('error_while_processing') + error.message);
    } finally {
      setLoading(false);
    }
  };

  const cleanText = (text) => {
    return text.replace(/\*/g, '').replace(/###/g, '');
  };

  const handleCopyText = () => {
    const textArea = document.createElement('textarea');
    textArea.value = storyContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy'); // Копируем текст в буфер обмена
    document.body.removeChild(textArea);
    setCopySuccess(t('text_copied')); // Сообщение об успешном копировании
  };

  const saveStory = async (storyTheme, content) => {
    try {
      const response = await axios.post(`${API_URL}/projects/${projectId}/stories`, {
        story_theme: storyTheme,
        story_text: content
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
    } catch (error) {
      console.error('Error saving post:', error);
    }
  };

  return (
    <div className="post_container">
      <span className="post_container_back_link" onClick={() => navigate(-1)}>{t('back')}</span>

      <h2>{t('story_result_heading')} <TextToggle text={storyTheme} /></h2>

      {loading && (
        <div className="loading-progress-bar">
          <ProgressBar animated now={progress} label={`${progress}%`} /> 
        </div>
      )}

      {storyContent && <div className="post-preview">{storyContent}</div>}

      {storyContent && (
        <button onClick={handleCopyText}>{t('copy_text')}</button> 
      )}

      {copySuccess && <div className="copy-success">{copySuccess}</div>}
    </div>
  );
}

export default CreateStory;